import React from 'react'

import digimark from './images/logo/digimark.png'
import cursor from './images/logo/cursor.png'
import analysis from './images/logo/analysis.png'
import content from './images/logo/content (1).png'
import coding from './images/logo/coding.png'

import './styles/AboutHero.css'

function AboutHero() {
  return (
    <>
    <p className='text-[2rem] sm:text-[3rem] font-semibold font-roboto tracking-wide text-slate-800 text-center py-[2.5rem] md:pt-[5rem] md:pb-[1rem]'>What We Offer</p>
    <hr className='mx-auto w-40 h-[0.1rem] bg-orange-600 my-1' />
    <div className='flex justify-center py-5 mb-10'>
        <div className='grid md:grid-cols-2 lg:grid-cols-3 px-[2rem]'>
            <div className='carddiv bg-[#F6F6F6] m-4 flex justify-center text-center px-2 py-4'>
                <div className='py-4'>
                    <div className='flex justify-center items-center'>
                        <div className='bg-orange-500 rounded-full p-[11px]'>
                            <img className='w-[3.5rem] h-[3.5rem]' src={digimark} alt="" />
                        </div>
                    </div>
                    <p className='pt-2 font-semibold'>Search Engine Optimization</p>
                    <p className='font-semibold'>(SEO)</p>
                    <p className='px-[2rem] pt-[1rem]'>Helps businesses improve their website's search engine ranking by optimizing their website content and structure.</p>
                </div>
            </div>
            <div className='carddiv bg-[#F6F6F6] m-4 flex justify-center text-center px-2 py-4'>
                <div className='py-4'>
                    <div className='flex justify-center'>
                        <div className='bg-orange-500 rounded-full p-[11px]'>
                            <img className='w-[3.5rem] h-[3.5rem]' src={cursor} alt="" />
                        </div>
                    </div>
                    <p className='pt-2 font-semibold'>Pay-Per-Click Advertising</p>
                    <p className='font-semibold'>(PPC)</p>
                    <p className='px-[2rem] pt-[1rem]'>Creates and manages effective PPC campaigns to help businesses increase their online visibility and generate leads.</p>
                </div>
            </div>
            <div className='carddiv bg-[#F6F6F6] m-4 flex justify-center text-center px-2 py-4'>
                <div className='py-4'>
                    <div className='flex justify-center'>
                        <div className='bg-orange-500 rounded-full p-[11px]'>
                            <img className='w-[3.5rem] h-[3.5rem]' src={digimark} alt="" />
                        </div>
                    </div>
                    <p className='pt-2 font-semibold'>Social Media Marketing</p>
                    <p className='px-[2rem] pt-[1rem]'>Helps businesses build a strong social media presence by creating engaging content and managing social media accounts.</p>
                </div>
            </div>
            <div className='carddiv bg-[#F6F6F6] m-4 flex justify-center text-center px-2 py-4'>
                <div className='py-4'>
                    <div className='flex justify-center'>
                        <div className='bg-orange-500 rounded-full p-[11px]'>
                            <img className='w-[3.5rem] h-[3.5rem]' src={analysis} alt="" />
                        </div>
                    </div>
                    <p className='pt-2 font-semibold'>Strategy Planning</p>
                    <p className='px-[2rem] pt-[1rem]'>Involve in-depth market analysis, developing actionable plans, and optimizing marketing tactics to drive business growth and achieve measurable success.</p>
                </div>
            </div>
            <div className='carddiv bg-[#F6F6F6] m-4 flex justify-center text-center px-2 py-4'>
                <div className='py-4'>
                    <div className='flex justify-center'>
                        <div className='bg-orange-500 rounded-full p-[11px]'>
                            <img className='w-[3.5rem] h-[3.5rem]' src={content} alt="" />
                        </div>
                    </div>
                    <p className='pt-2 font-semibold'>Content Marketing</p>
                    <p className='px-[2rem] pt-[1rem]'>Creates high-quality content that helps businesses attract and retain customers.</p>
                </div>
            </div>
            <div className='carddiv bg-[#F6F6F6] m-4 flex justify-center text-center px-2 py-4'>
                <div className='py-4'>
                    <div className='flex justify-center'>
                        <div className='bg-orange-500 rounded-full p-[11px]'>
                            <img className='w-[3.5rem] h-[3.5rem]' src={coding} alt="" />
                        </div>
                    </div>
                    <p className='pt-2 font-semibold'>Website Design and Development</p>
                    <p className='px-[2rem] pt-[1rem]'>Designs and develops responsive and user-friendly websites that are optimized for search engines and deliver a great user experience.</p>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default AboutHero