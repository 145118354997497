import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import GetInTouch from '../GetInTouch'
import PageHeading from '../PageHeading'
import { Helmet } from 'react-helmet';


function Contact() {

  const pathname = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
    <Helmet>
      <title>{ 'Dynastical Communication | Contact' }</title>
    </Helmet>
    <PageHeading title={'Contact Us'} pagename={'Contact'} />
    <iframe title='gmap' className='flex justify-center w-full h-[50vh] bg-slate-100' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.3334798689693!2d73.82658351486437!3d18.513826387412927!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bfb12a6fe48d%3A0x7c5faf18901673bf!2sDynastical%20Communication%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1681211902839!5m2!1sen!2sin" width="1500" height="600" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    <GetInTouch />
    </>
  )
}

export default Contact