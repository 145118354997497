import React from 'react'

function BlogPage(props) {
  return (
    <>
    <div className='px-20 py-10'>
        <p className='font-roboto text-center pt-5 pb-2 text-2xl text-slate-800'>{props.title_one}</p>
        <p className='font-roboto text-center pb-5 text-5xl font-semibold text-slate-800'>{props.title_two}</p>
        <hr />
        <p className='font-roboto mt-4 pt-4 text-justify text-lg'><span className='px-6'></span>{props.para_one}</p>
        <p className='font-roboto py-2 text-justify text-lg'><span className='px-6'></span>{props.para_two}</p>
        <p className='font-roboto py-2 text-justify text-lg'><span className='px-6'></span>{props.para_three}</p>
    </div>
    </>
  )
}

export default BlogPage