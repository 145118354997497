import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

function Career() {

    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [contact, setContact] = useState('')
    const [position, setPosition] = useState('')
    const [experience, setExperience] = useState('')
    const [message, setMessage] = useState('')

    const submitHandler = (e) => {

        e.preventDefault();

        if (window.Email) {
            window.Email.send({
                Username : "dynasticwebdev@yopmail.com",
                Password : "BCBA4DE1893A57620E1F077D4D1D0AABA1E8",
                Host : "smtp.elasticemail.com",
                Port : 2525,
                To : 'corporate.dynastical@gmail.com',
                From : "corporate.dynastical@gmail.com",
                Subject : `Applying for the position of ${position}.`,
                Body : `Applicant Name: ${fullName} <br> Email: ${email} <br> Contact: ${contact} <br> Position Applied: ${position} <br> Experience In Years: ${experience} <br> About: ${message}`
                }).then(
              alert('Email Sent Successfully')
            );
        }

    }

  return (
    <>
    <Helmet>
      <title>{ 'Dynastical Communication | Career' }</title>
    </Helmet>
    <div className='grid lg:grid-cols-2'>
        <div className='p-10 bg-slate-900'>
            <form className='flex flex-col' onSubmit={submitHandler}>
                <input value={fullName} onChange={(e)=>setFullName(e.target.value)} className='rounded-xl p-[0.5rem] m-[0.5rem] border border-black' type="text" placeholder='Full Name' required />
                <div className='flex '>
                    <input value={email} onChange={(e)=>setEmail(e.target.value)} className='rounded-xl w-full p-[0.5rem] m-[0.5rem] border border-black' type="text" placeholder='Email' required />
                    <input value={contact} onChange={(e)=>setContact(e.target.value)} className='rounded-xl w-full p-[0.5rem] m-[0.5rem] border border-black' type="text" placeholder='Contact Number' required />
                </div>

                <label className='p-[0.5rem] mx-[0.5rem] mb-[0.1rem] text-white font-semibold tracking-wider' for="position">Select Position You Are Applying For :</label>
                <select value={position} onChange={(e)=>setPosition(e.target.value)} className='p-[0.5rem] mx-[0.5rem] mb-[0.5rem] border border-black' name="position" id="position" required>
                    <option className='text-slate-500 text-[1.1rem] font-semibold' value="Graphic Designer">Graphic Designer</option>
                    <option className='text-slate-500 text-[1.1rem] font-semibold' value="Content Writer (Hindi, English, Marathi)">Content Writer (Hindi, English, Marathi)</option>
                    <option className='text-slate-500 text-[1.1rem] font-semibold' value="Website Developer">Website Developer</option>
                    <option className='text-slate-500 text-[1.1rem] font-semibold' value="Digital Marketer">Digital Marketer</option>
                    <option className='text-slate-500 text-[1.1rem] font-semibold' value="Video Editor">Video Editor</option>
                    <option className='text-slate-500 text-[1.1rem] font-semibold' value="Creative Head">Creative Head</option>
                </select>
                <input value={experience} onChange={(e)=>setExperience(e.target.value)} className='rounded-xl p-[0.5rem] m-[0.5rem] border border-black' type="number" placeholder='Experience In Years' required />
                <textarea value={message} onChange={(e)=>setMessage(e.target.value)} className='rounded-xl p-[0.5rem] m-[0.5rem] border border-black' rows={5} cols={10} type="text" placeholder='Brief Introduction About Yourself' required/>
                <button className='bg-orange-500 p-[0.5rem] m-[0.5rem] border border-black'>Submit</button>
            </form>
        </div>
        <div className='flex justify-center'>
            <div>
                <p className='text-[2rem] md:text-[2.8rem] text-center font-roboto font-bold pb-4'>Current Openings</p>
                <p className='border border-slate-900 w-[80vw] md:w-[35vw] hover:bg-[#152440] hover:text-white text-[1rem] md:text-[1.2rem] font-semibold rounded-full py-[0.8rem] text-center my-[0.7rem]'>Graphic Designer</p>
                <p className='border border-slate-900 w-[80vw] md:w-[35vw] hover:bg-[#152440] hover:text-white text-[1rem] md:text-[1.2rem] font-semibold rounded-full py-[0.8rem] text-center my-[0.7rem]'>Content Writer (Hindi, English, Marathi)</p>
                <p className='border border-slate-900 w-[80vw] md:w-[35vw] hover:bg-[#152440] hover:text-white text-[1rem] md:text-[1.2rem] font-semibold rounded-full py-[0.8rem] text-center my-[0.7rem]'>Website Developer</p>
                <p className='border border-slate-900 w-[80vw] md:w-[35vw] hover:bg-[#152440] hover:text-white text-[1rem] md:text-[1.2rem] font-semibold rounded-full py-[0.8rem] text-center my-[0.7rem]'>Digital Marketer</p>
                <p className='border border-slate-900 w-[80vw] md:w-[35vw] hover:bg-[#152440] hover:text-white text-[1rem] md:text-[1.2rem] font-semibold rounded-full py-[0.8rem] text-center my-[0.7rem]'>Video Editor</p>
                <p className='border border-slate-900 w-[80vw] md:w-[35vw] hover:bg-[#152440] hover:text-white text-[1rem] md:text-[1.2rem] font-semibold rounded-full py-[0.8rem] text-center my-[0.7rem]'>Creative Head</p>
            </div>
        </div>
    </div>
    </>
  )
}

export default Career