import './App.css';
import Navbar from './components/Navbar';

import { Routes, Route } from 'react-router-dom';
import Blog from './components/routes/Blog';
import BlogPage from './components/pages/BlogPage';
import Main from './components/routes/Main';
import Footer from './components/Footer';
import About from './components/routes/About';
import Service from './components/routes/Service';
import Contact from './components/routes/Contact';
import PrivacyPolicy from './components/PrivacyPolicy';
import Career from './components/Career';
import ScrollToTop from './components/ScrollToTop';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <>
    <BrowserRouter>
      <Navbar />
      <ScrollToTop />
      <Routes >
        <Route exact path='/' element={<Main />} />
        <Route exact path='about' element={<About />} />
        <Route exact path='service' element={<Service />} />
        <Route exact path='blog' element={<Blog />} />
        <Route exact path='blog_one' element={<BlogPage
        title_one={'Unlock the Power of'}
        title_two={'Trendy Digital Marketing & Advertising'}
        para_one={'The world of marketing and advertising has transformed rapidly over the years, and the emergence of digital media has played a crucial role in this evolution. The rise of the internet and social media has led to an increase in the demand for digital marketing and advertising. As businesses strive to gain a competitive edge in their industries, digital marketing has become a critical aspect of their overall marketing strategy.'}
        para_two={'Choosing the best digital marketing agency is essential for businesses that want to leverage the power of digital marketing and advertising to boost their brand image and drive revenue. A reputable digital marketing company can provide a range of digital marketing services, including online marketing, branding, content marketing, content strategy, website development, web designing, social media marketing (SMM), social media optimization (SMO), search engine marketing (SEM), and search engine optimization (SEO). One of the essential aspects of digital marketing is branding. A strong business branding strategy can help businesses establish a unique identity and differentiate themselves from their competitors. Branding marketing involves creating a consistent brand image across all marketing channels to create a lasting impression on customers.'}
        para_three={"Content marketing is another essential aspect of digital marketing. Creating valuable and engaging content can help businesses attract and retain customers, build trust, and establish authority in their industry. A content strategy that aligns with the overall marketing goals can drive traffic to the website and boost search engine rankings.Website development and web designing are also critical components of digital marketing. A well-designed website can enhance the user experience and improve conversion rates. Social media marketing (SMM) and social media optimization (SMO) can help businesses reach a broader audience and engage with customers on various social media platforms. Search engine marketing (SEM) and search engine optimization (SEO) marketing are also crucial for businesses that want to boost their online visibility and improve search engine rankings. SEO involves optimizing the website to improve its search engine ranking, while SEM involves using paid advertising to appear at the top of search engine results. In conclusion, digital marketing and advertising are essential for businesses that want to stay competitive in today's digital age. Choosing the best digital marketing agency can help businesses develop a comprehensive digital marketing strategy that can drive traffic, boost revenue, and build a strong brand image."}
        />} />
        <Route exact path='blog_two' element={<BlogPage
        title_one={'Bringing Your Ideas To Life With'}
        title_two={'Creative Graphic Designs'}
        para_one={"The world of graphic design is a vast and diverse landscape that encompasses a wide range of services and skills. From traditional print design to cutting-edge digital art, there are countless ways that graphic designers can help individuals and businesses communicate their messages and ideas. At the heart of graphic design services is the graphic itself – the visual representation of an idea or concept. Whether it's a logo, a brochure, a website, or a social media post, graphics are the building blocks of effective communication. And with the rise of digital media, graphic design services have become more important than ever. Digital designers and creative designers are at the forefront of this new age of graphic design. They use digital tools and technologies to create stunning visuals that capture the attention of audiences across a range of platforms."}
        para_two={"From social media posts to video animations, digital designers are constantly pushing the boundaries of what's possible in the world of graphics and design. But it's not just about creating pretty pictures. The best graphic designers understand that effective graphic design is about communicating a message in the most efficient and impactful way possible. They combine creativity with strategic thinking to create graphics and design that truly resonates with their target audience. At the core of creative graphic design is the idea of storytelling. A great graphic design art tells a story and creates an emotional connection with its audience. It can evoke a feeling of joy, excitement, or even sadness. Whatever the emotion, the best graphic design has the power to make people feel something."}
        para_three={"Professional graphic designers also play a crucial role in the world of digital marketing and advertising. They work closely with digital marketing agencies to create compelling visuals that drive engagement and conversion rates. Whether it's a banner ad, a landing page, or a social media campaign, graphic design plays a critical role in the success of digital marketing efforts. In today's digital age, the importance of graphic design services cannot be overstated. The best graphic designers are those who combine creativity with strategic thinking, and who have a deep understanding of both traditional and digital design. And for businesses looking to make a splash in the digital world, partnering with the best digital marketing agency and graphic design team can make all the difference."}
        />} />
        <Route exact path='blog_three' element={<BlogPage
        title_two={"Let's colour your brand with confidence!"}
        para_one={'At our digital marketing agency, we understand the importance of branding and advertising in the online world. With the rise of internet marketing, businesses are realizing the need to invest in digital marketing services to stay ahead of the competition. As the best digital marketing agency in Pune, we specialize in 360° marketing solutions that encompass branding, SEO, SMO, PPC, PR, web design, graphics designing and website development. Our strategic digital marketing agency is focused on helping brands grow by offering result-driven SEO and ROI-driven PPC campaigns.'}
        para_two={'We believe that every brand is unique, and our expert team of digital marketing professionals will work with you to understand your specific business needs and goals. Our online marketing solutions are customized to suit your requirements, ensuring that you get the best possible return on investment. At our advertising and digital marketing company, we pride ourselves on being the No.1 digital marketing agency in Pune. We provide budget-friendly costs without compromising on the quality of our services. We offer a range of top digital marketing services that will help you to get leads with SEO, while also enhancing your brand identity through our branding marketing expertise.'}
        para_three={"Our team of graphic designers, website developers and digital marketing experts are committed to delivering high-quality services that meet your business objectives. We have a proven track record of delivering successful digital marketing campaigns that have helped our clients to achieve their goals and grow their businesses. So, if you're looking for a strategic digital marketing agency that can help you to digitize your business and take it to the next level, look no further than our online marketing company. Contact us today to learn more about how we can help you to colour your brand with our creativity and expertise."}
        />} />
        <Route exact path='blog/blog_one' element={<BlogPage
        title_one={'Unlock the Power of'}
        title_two={'Trendy Digital Marketing & Advertising'}
        para_one={'The world of marketing and advertising has transformed rapidly over the years, and the emergence of digital media has played a crucial role in this evolution. The rise of the internet and social media has led to an increase in the demand for digital marketing and advertising. As businesses strive to gain a competitive edge in their industries, digital marketing has become a critical aspect of their overall marketing strategy.'}
        para_two={'Choosing the best digital marketing agency is essential for businesses that want to leverage the power of digital marketing and advertising to boost their brand image and drive revenue. A reputable digital marketing company can provide a range of digital marketing services, including online marketing, branding, content marketing, content strategy, website development, web designing, social media marketing (SMM), social media optimization (SMO), search engine marketing (SEM), and search engine optimization (SEO). One of the essential aspects of digital marketing is branding. A strong business branding strategy can help businesses establish a unique identity and differentiate themselves from their competitors. Branding marketing involves creating a consistent brand image across all marketing channels to create a lasting impression on customers.'}
        para_three={"Content marketing is another essential aspect of digital marketing. Creating valuable and engaging content can help businesses attract and retain customers, build trust, and establish authority in their industry. A content strategy that aligns with the overall marketing goals can drive traffic to the website and boost search engine rankings.Website development and web designing are also critical components of digital marketing. A well-designed website can enhance the user experience and improve conversion rates. Social media marketing (SMM) and social media optimization (SMO) can help businesses reach a broader audience and engage with customers on various social media platforms. Search engine marketing (SEM) and search engine optimization (SEO) marketing are also crucial for businesses that want to boost their online visibility and improve search engine rankings. SEO involves optimizing the website to improve its search engine ranking, while SEM involves using paid advertising to appear at the top of search engine results. In conclusion, digital marketing and advertising are essential for businesses that want to stay competitive in today's digital age. Choosing the best digital marketing agency can help businesses develop a comprehensive digital marketing strategy that can drive traffic, boost revenue, and build a strong brand image."}
        />} />
        <Route exact path='blog/blog_two' element={<BlogPage
        title_one={'Bringing Your Ideas To Life With'}
        title_two={'Creative Graphic Designs'}
        para_one={"The world of graphic design is a vast and diverse landscape that encompasses a wide range of services and skills. From traditional print design to cutting-edge digital art, there are countless ways that graphic designers can help individuals and businesses communicate their messages and ideas. At the heart of graphic design services is the graphic itself – the visual representation of an idea or concept. Whether it's a logo, a brochure, a website, or a social media post, graphics are the building blocks of effective communication. And with the rise of digital media, graphic design services have become more important than ever. Digital designers and creative designers are at the forefront of this new age of graphic design. They use digital tools and technologies to create stunning visuals that capture the attention of audiences across a range of platforms."}
        para_two={"From social media posts to video animations, digital designers are constantly pushing the boundaries of what's possible in the world of graphics and design. But it's not just about creating pretty pictures. The best graphic designers understand that effective graphic design is about communicating a message in the most efficient and impactful way possible. They combine creativity with strategic thinking to create graphics and design that truly resonates with their target audience. At the core of creative graphic design is the idea of storytelling. A great graphic design art tells a story and creates an emotional connection with its audience. It can evoke a feeling of joy, excitement, or even sadness. Whatever the emotion, the best graphic design has the power to make people feel something."}
        para_three={"Professional graphic designers also play a crucial role in the world of digital marketing and advertising. They work closely with digital marketing agencies to create compelling visuals that drive engagement and conversion rates. Whether it's a banner ad, a landing page, or a social media campaign, graphic design plays a critical role in the success of digital marketing efforts. In today's digital age, the importance of graphic design services cannot be overstated. The best graphic designers are those who combine creativity with strategic thinking, and who have a deep understanding of both traditional and digital design. And for businesses looking to make a splash in the digital world, partnering with the best digital marketing agency and graphic design team can make all the difference."}
        />} />
        <Route exact path='blog/blog_three' element={<BlogPage
        title_two={"Let's colour your brand with confidence!"}
        para_one={'At our digital marketing agency, we understand the importance of branding and advertising in the online world. With the rise of internet marketing, businesses are realizing the need to invest in digital marketing services to stay ahead of the competition. As the best digital marketing agency in Pune, we specialize in 360° marketing solutions that encompass branding, SEO, SMO, PPC, PR, web design, graphics designing and website development. Our strategic digital marketing agency is focused on helping brands grow by offering result-driven SEO and ROI-driven PPC campaigns.'}
        para_two={'We believe that every brand is unique, and our expert team of digital marketing professionals will work with you to understand your specific business needs and goals. Our online marketing solutions are customized to suit your requirements, ensuring that you get the best possible return on investment. At our advertising and digital marketing company, we pride ourselves on being the No.1 digital marketing agency in Pune. We provide budget-friendly costs without compromising on the quality of our services. We offer a range of top digital marketing services that will help you to get leads with SEO, while also enhancing your brand identity through our branding marketing expertise.'}
        para_three={"Our team of graphic designers, website developers and digital marketing experts are committed to delivering high-quality services that meet your business objectives. We have a proven track record of delivering successful digital marketing campaigns that have helped our clients to achieve their goals and grow their businesses. So, if you're looking for a strategic digital marketing agency that can help you to digitize your business and take it to the next level, look no further than our online marketing company. Contact us today to learn more about how we can help you to colour your brand with our creativity and expertise."}
        />} />
        <Route path='contact' element={<Contact />} />
        <Route path='privacy-policy' element={<PrivacyPolicy />} />
        <Route path='career' element={<Career />} />
      </Routes>
      <Footer />
    </BrowserRouter>
    </>
  );
}

export default App;
