import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

import social from '../images/logo/social-media.png'
import seo from '../images/logo/seo (1).png'
import seotwo from '../images/logo/SEO.png'
import performance from '../images/logo/performance.png'

import ecommerce from '../images/logo/ecommerce.png'
import dynamic from '../images/logo/dynamic.png'
import static_web from '../images/logo/static_web.png'
import new_website from '../images/logo/new_website.png'
import redesign from '../images/logo/redesign.png'
import web_hosting from '../images/logo/web_hosting.png'

import blog from '../images/logo/blog.png'
import bullhorn from '../images/logo/bullhorn.png'
import writing from '../images/logo/writing.png'
import writer from '../images/logo/writer.png'
import content_editing from '../images/logo/content_editing.png'
import brochure from '../images/logo/brochure.png'
import badge from '../images/logo/badge.png'
import video_call from '../images/logo/video-call.png'

import email_marketing from '../images/logo/email-marketing.png'
import trending_topic from '../images/logo/trending-topic.png'

import print from '../images/logo/print.png'
import packaging from '../images/logo/packaging.png'

import high_visibility from '../images/logo/high-visibility.png'
import rebranding from '../images/logo/rebranding.png'
import competitor from '../images/logo/competitor.png'
import advantage from '../images/logo/advantage.png'
import PageHeading from '../PageHeading'
import { Helmet } from 'react-helmet';

function Service() {

    const pathname = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

  return (
    <>
    <Helmet>
        <title>{ 'Dynastical Communication | Services' }</title>
    </Helmet>
    <PageHeading title={'Services'} pagename={'Services'} />
    <div className='md:flex justify-center items-center'>
        <div className='md:w-[50vw] w-[95vw] px-[2rem] py-[1rem] md:px-[4rem]'>
            <p className='text-[1.5rem] md:text-4xl font-bold my-2'>DIGITAL MARKETING</p>
            <p className='text-xl font-semibold text-orange-500 mb-2 md:my-4'>Flourish digitally with us !</p>
            <p className='font-semibold text-blue-900 text-justify'>When it comes to picking the best digital marketing agency to help your business reach a broader audience, people think of us as the No. 1 digital marketing agency in Pune. With our all-in-one online marketing tools, including branding, SEO, SMO, PPC, PR, and web design, customers receive their 360° marketing solution. As a fully professional digital marketing consultant, helping brands grow their customer base is our target, and to make it possible, we generate highly engaging social media content. Our digital marketing company provides top digital marketing services in Pune along with the budget & result projection with in-depth research. We are a proven internet marketing agency that offers complete online marketing solutions that lift your business to the next level with a new results-driven end-to-end brand strategy at a budget-friendly cost.</p>
        </div>
        <div className='md:w-[50vw] py-[1rem] md:px-[4rem] bg-orange-500 border-b-2 border-white'>
            <div className='my-[2rem] md:my-[4rem] px-[2rem] flex justify-start items-center gap-10'>
                <img className='w-16 bg-white p-3 rounded-lg' src={social} alt="" />
                <p className='text-blue-900 text-2xl font-semibold'>Social Media Marketing</p>
            </div>
            <div className='my-[2rem] md:my-[4rem] px-[2rem] flex justify-start items-center gap-10'>
                <img className='w-16 bg-white p-3 rounded-lg' src={seo} alt="" />
                <p className='text-blue-900 text-2xl font-semibold'>Search Engine Marketing</p>
            </div>
            <div className='my-[2rem] md:my-[4rem] px-[2rem] flex justify-start items-center gap-10'>
                <img className='w-16 bg-white p-3 rounded-lg' src={seotwo} alt="" />
                <p className='text-blue-900 text-2xl font-semibold'>Search Engine Optimization</p>
            </div>
            <div className='my-[2rem] md:my-[4rem] px-[2rem] flex justify-start items-center gap-10'>
                <img className='w-16 bg-white p-3 rounded-lg' src={performance} alt="" />
                <p className='text-blue-900 text-2xl font-semibold'>Social Media Optimization</p>
            </div>
            
        </div>
    </div>
    <div className='md:flex justify-center items-center'>
        <div className='md:w-[50vw] py-[1rem] md:px-[4rem] bg-orange-500'>
            <div className='my-[2rem] px-[2rem] flex justify-start items-center gap-10'>
                <img className='w-16 bg-white p-3 rounded-lg' src={ecommerce} alt="" />
                <p className='text-blue-900 text-2xl font-semibold'>E-Commerce Websites</p>
            </div>
            <div className='my-[2rem] px-[2rem] flex justify-start items-center gap-10'>
                <img className='w-16 bg-white p-3 rounded-lg' src={static_web} alt="" />
                <p className='text-blue-900 text-2xl font-semibold'>Static Websites</p>
            </div>
            <div className='my-[2rem] px-[2rem] flex justify-start items-center gap-10'>
                <img className='w-16 bg-white p-3 rounded-lg' src={dynamic} alt="" />
                <p className='text-blue-900 text-2xl font-semibold'>Dynamic Websites</p>
            </div>
            <div className='my-[2rem] px-[2rem] flex justify-start items-center gap-10'>
                <img className='w-16 bg-white p-3 rounded-lg' src={new_website} alt="" />
                <p className='text-blue-900 text-2xl font-semibold'>New Website Design</p>
            </div>
            <div className='my-[2rem] px-[2rem] flex justify-start items-center gap-10'>
                <img className='w-16 bg-white p-3 rounded-lg' src={redesign} alt="" />
                <p className='text-blue-900 text-2xl font-semibold'>Redesigning a Website</p>
            </div>
            <div className='my-[2rem] px-[2rem] flex justify-start items-center gap-10'>
                <img className='w-16 bg-white p-3 rounded-lg' src={web_hosting} alt="" />
                <p className='text-blue-900 text-2xl font-semibold'>Website Hosting</p>
            </div>
        </div>
        <div className='md:w-[50vw] w-[95vw] px-[2rem] py-[1rem] md:px-[4rem] border-b-2 border-orange-500 md:border-white'>
            <p className='text-[1.5rem] md:text-4xl font-bold my-2'>WEB DESIGNING</p>
            <p className='text-xl font-semibold text-orange-500 mb-2 md:my-4'>A professional website defines you & your business !</p>
            <p className='font-semibold text-blue-900 text-justify'>We are a proactive web development company in Pune whose goal is to create SEO-compatible websites that support the development of your organization's online presence. Also, as the best SEO firm in Pune, we offer the best web hosting, web designing website development, and SEO services to assist your company to appear on the very first page of search engines. Our website marketing company creates a dynamic website that turns visitors into clients and consumers into devoted customers. Our highly qualified team builds your website using relevant technologies to make it flexible, adaptive, and vibrant. Our exceptional website designing incorporates attributes like mobile friendliness, responsiveness, usability, a beautiful web layout design, appealing graphic design, interesting content, effective call to actions, original content, and is designed in accordance with Google search engine regulations. Several customers have benefited from the assistance of our expert web designers in Pune in building business websites, corporate websites, professional websites, etc, and ranking them at the top of Google searches to reach customers and boost sales by 100%.</p>
        </div>
    </div>
    <div className='md:flex justify-center items-center'>
        <div className='md:w-[50vw] w-[95vw] px-[2rem] py-[1rem] md:px-[4rem]'>
            <p className='text-[1.5rem] md:text-4xl font-bold my-2'>CONTENT MARKETING</p>
            <p className='text-xl font-semibold text-orange-500 mb-2 md:my-4'>Content is king & we are king-makers !</p>
            <p className='font-semibold text-blue-900 text-justify'>We always have a well-defined content strategy to pull in, maintain, and expand an audience through the creation and distribution of relevant blog posts, videos, and other media. When it comes time to buy what you sell, our online content strategy maintains your organization at the top of customers' minds, builds competence, and boosts brand awareness.Our innovative and SEO-friendly web content marketing for businesses and peerless online content marketing strategy have yielded the best results for various corporate clients in Pune. Nowadays, a good content strategy is hard to find. In this rarity, we promise you the finest content marketing campaign that is appropriate for your specific brand and that also ensures the organic reach of your company's website & social media pages at an affordable price.</p>
        </div>
        <div className='md:w-[50vw] py-[1rem] md:px-[4rem] md:grid md:grid-cols-2 bg-orange-500 border-b-2 border-white'>
            <div>
                <div className='my-[2rem] px-[2rem] md:px-[1rem] flex justify-start items-center gap-10'>
                    <img className='w-16 bg-white p-3 rounded-lg' src={writing} alt="" />
                    <p className='text-blue-900 text-2xl font-semibold'>Website content writing</p>
                </div>
                <div className='my-[2rem] px-[2rem] md:px-[1rem] flex justify-start items-center gap-10'>
                    <img className='w-16 bg-white p-3 rounded-lg' src={writer} alt="" />
                    <p className='text-blue-900 text-2xl font-semibold'>Creative content writing</p>
                </div>
                <div className='my-[2rem] px-[2rem] md:px-[1rem] flex justify-start items-center gap-10'>
                    <img className='w-16 bg-white p-3 rounded-lg' src={bullhorn} alt="" />
                    <p className='text-blue-900 text-2xl font-semibold'>Social media content</p>
                </div>
                <div className='my-[2rem] px-[2rem] md:px-[1rem] flex justify-start items-center gap-10'>
                    <img className='w-16 bg-white p-3 rounded-lg' src={content_editing} alt="" />
                    <p className='text-blue-900 text-2xl font-semibold'>Content editing</p>
                </div>
                <div className='my-[2rem] px-[2rem] md:px-[1rem] flex justify-start items-center gap-10'>
                    <img className='w-16 bg-white p-3 rounded-lg' src={brochure} alt="" />
                    <p className='text-blue-900 text-2xl font-semibold'>Brochure content</p>
                </div>
            </div>
            <div>
                <div className='my-[2rem] px-[2rem] md:px-[1rem] flex justify-start items-center gap-10'>
                    <img className='w-16 bg-white p-3 rounded-lg' src={badge} alt="" />
                    <p className='text-blue-900 text-2xl font-semibold'>Branding content</p>
                </div>
                <div className='my-[2rem] px-[2rem] md:px-[1rem] flex justify-start items-center gap-10'>
                    <img className='w-16 bg-white p-3 rounded-lg' src={video_call} alt="" />
                    <p className='text-blue-900 text-2xl font-semibold'>Video content</p>
                </div>
                <div className='my-[2rem] px-[2rem] md:px-[1rem] flex justify-start items-center gap-10'>
                    <img className='w-16 bg-white p-3 rounded-lg' src={blog} alt="" />
                    <p className='text-blue-900 text-2xl font-semibold'>Blog writing</p>
                </div>
                <div className='my-[2rem] px-[2rem] md:px-[1rem] flex justify-start items-center gap-10'>
                    <img className='w-16 bg-white p-3 rounded-lg' src={seotwo} alt="" />
                    <p className='text-blue-900 text-2xl font-semibold'>Advertising & branding content</p>
                </div>
            </div>
        </div>
    </div>
    <div className='md:flex justify-center items-center'>
        <div className='md:w-[50vw] py-[1rem] md:px-[4rem] bg-orange-500'>
            <div className='my-[2rem] px-[2rem] flex justify-start items-center gap-10'>
                <img className='w-16 bg-white p-3 rounded-lg' src={badge} alt="" />
                <p className='text-blue-900 text-2xl font-semibold'>Logo Designing</p>
            </div>
            <div className='my-[2rem] px-[2rem] flex justify-start items-center gap-10'>
                <img className='w-16 bg-white p-3 rounded-lg' src={email_marketing} alt="" />
                <p className='text-blue-900 text-2xl font-semibold'>Brand Messaging</p>
            </div>
            <div className='my-[2rem] px-[2rem] flex justify-start items-center gap-10'>
                <img className='w-16 bg-white p-3 rounded-lg' src={trending_topic} alt="" />
                <p className='text-blue-900 text-2xl font-semibold'>Brand Strategy</p>
            </div>
            <div className='my-[2rem] px-[2rem] flex justify-start items-center gap-10'>
                <img className='w-16 bg-white p-3 rounded-lg' src={new_website} alt="" />
                <p className='text-blue-900 text-2xl font-semibold'>Brand Guidelines</p>
            </div>
            <div className='my-[2rem] px-[2rem] flex justify-start items-center gap-10'>
                <img className='w-16 bg-white p-3 rounded-lg' src={rebranding} alt="" />
                <p className='text-blue-900 text-2xl font-semibold'>Other Branding Techniques</p>
            </div>
        </div>
        <div className='md:w-[50vw] w-[95vw] px-[2rem] py-[1rem] md:px-[4rem] border-b-2 border-orange-500 md:border-white'>
            <p className='text-[1.5rem] md:text-4xl font-bold my-2'>BRANDING SOLUTIONS</p>
            <p className='text-xl font-semibold text-orange-500 mb-2 md:my-4'>Increase your online presence with our innovative branding !</p>
            <p className='font-semibold text-blue-900 text-justify'>Dynastical Communication provides comprehensive branding solutions to help businesses develop a unique and memorable brand identity that resonates with their target audience. We are the best business branding and marketing company in Pune that sticks to the realistic strategy of crafting an individual identity for your company in the brains of your target market and consumers. We develop and strengthen the bond between your brand and customers to increase the digital presence of your business through company branding. The following services are offered as part of branding - Logo Designing, Brand Messaging, Brand Strategy, Brand guidelines and other branding techniques.</p>
        </div>
    </div>
    <div className='md:flex justify-center items-center'>
        <div className='md:w-[50vw] w-[95vw] px-[2rem] py-[1rem] md:px-[4rem]'>
            <p className='text-[1.5rem] md:text-4xl font-bold my-2'>GRAPHICS DESIGNING</p>
            <p className='text-xl font-semibold text-orange-500 mb-2 md:my-4'>Designs that captivate, brand identities that last !</p>
            <p className='font-semibold text-blue-900 text-justify'>Dynastical Communication provides professional and creative graphic design solutions to help businesses develop a strong and memorable visual identity. Our graphic design services include logo design, branding, brochure design, flyer design, social media graphics, packaging design, and more. Our team of experienced graphic designers works closely with clients to understand their business and target audience, and create visually appealing designs that effectively communicate their message. We believe that every design element should work together to convey a cohesive and consistent brand identity, from the logo to the website to the marketing materials. At Dynastical Communication, we are committed to delivering high-quality graphic design solutions that help businesses achieve their marketing goals and stand out from the competition. Whether you need a new logo, brochure, or social media graphics, we have the expertise and creativity to bring your vision to life. So, contact us today and let us help you create stunning designs that captivate your audience !</p>
        </div>
        <div className='md:w-[50vw] py-[1rem] md:px-[4rem] bg-orange-500 border-b-2 border-white'>
            <div className='my-[2rem] md:my-[4rem] px-[2rem] flex justify-start items-center gap-10'>
                <img className='w-16 bg-white p-3 rounded-lg' src={print} alt="" />
                <p className='text-blue-900 text-2xl font-semibold'>Print Designing</p>
            </div>
            <div className='my-[2rem] md:my-[4rem] px-[2rem] flex justify-start items-center gap-10'>
                <img className='w-16 bg-white p-3 rounded-lg' src={packaging} alt="" />
                <p className='text-blue-900 text-2xl font-semibold'>Packaging Designing</p>
            </div>
            <div className='my-[2rem] md:my-[4rem] px-[2rem] flex justify-start items-center gap-10'>
                <img className='w-16 bg-white p-3 rounded-lg' src={seotwo} alt="" />
                <p className='text-blue-900 text-2xl font-semibold'>Advertising & Marketing Designing</p>
            </div>
            <div className='my-[2rem] md:my-[4rem] px-[2rem] flex justify-start items-center gap-10'>
                <img className='w-16 bg-white p-3 rounded-lg' src={new_website} alt="" />
                <p className='text-blue-900 text-2xl font-semibold'>Publication Designing</p>
            </div>
            <div className='my-[2rem] md:my-[4rem] px-[2rem] flex justify-start items-center gap-10'>
                <img className='w-16 bg-white p-3 rounded-lg' src={redesign} alt="" />
                <p className='text-blue-900 text-2xl font-semibold'>Creative Art Designing</p>
            </div>
        </div>
    </div>
    <div className='md:flex justify-center items-center'>
        <div className='md:w-[50vw] py-[1rem] md:px-[4rem] bg-orange-500'>
            <div className='my-[2rem] px-[2rem] flex justify-start items-center gap-10'>
                <img className='w-16 bg-white p-3 rounded-lg' src={advantage} alt="" />
                <p className='text-blue-900 text-2xl font-semibold'>Plan strategic marketing campaigns.</p>
            </div>
            <div className='my-[2rem] px-[2rem] flex justify-start items-center gap-10'>
                <img className='w-16 bg-white p-3 rounded-lg' src={trending_topic} alt="" />
                <p className='text-blue-900 text-2xl font-semibold'>Boost Sales and Earnings.</p>
            </div>
            <div className='my-[2rem] px-[2rem] flex justify-start items-center gap-10'>
                <img className='w-16 bg-white p-3 rounded-lg' src={high_visibility} alt="" />
                <p className='text-blue-900 text-2xl font-semibold'>Enhance your exposure.</p>
            </div>
            <div className='my-[2rem] px-[2rem] flex justify-start items-center gap-10'>
                <img className='w-16 bg-white p-3 rounded-lg' src={rebranding} alt="" />
                <p className='text-blue-900 text-2xl font-semibold'>Complete rebranding.</p>
            </div>
            <div className='my-[2rem] px-[2rem] flex justify-start items-center gap-10'>
                <img className='w-16 bg-white p-3 rounded-lg' src={competitor} alt="" />
                <p className='text-blue-900 text-2xl font-semibold'>Studying rivals and devising strategies appropriately.</p>
            </div>
        </div>
        <div className='md:w-[50vw] w-[95vw] px-[2rem] py-[1rem] md:px-[4rem] md:border-white'>
            <p className='text-[1.5rem] md:text-4xl font-bold my-2'>STRATEGY PLANNING</p>
            <p className='text-xl font-semibold text-orange-500 mb-2 md:my-4'>Planning strategies that always works !</p>
            <p className='font-semibold text-blue-900 text-justify'>Dynastical Communication provides strategic planning services to help businesses develop a clear and actionable roadmap for achieving their marketing and business goals. By creating an efficient digital marketing strategy, we are passionate about helping our clients achieve their business goals by enhancing their online exposure, generating more inquiries, and eventually boosting sales. Our company has a team of competent digital marketing specialists that are masters at creating and implementing fruitful online marketing strategies and digital advertising strategies. In order to keep our clients ahead of their peers, we remain knowledgeable about the constantly evolving field of digital media strategy and marketing approaches and keep up with the most recent developments.</p>
        </div>
    </div>
    </>
  )
}

export default Service