import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import AboutHero from '../AboutHero'
import AboutEnd from '../AboutEnd'
import DirectorInfo from '../DirectorInfo'
import PageHeading from '../PageHeading'
import { Helmet } from 'react-helmet';

function About() {

  const pathname = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
    <Helmet>
        <title>{ 'Dynastical Communication | About Us' }</title>
    </Helmet>
    <PageHeading title={'About Us'} pagename={'About'} />
    <div className='text-center p-[3rem] md:pt-[2rem] md:px-[7rem]'>
        <div className='text-[#152440] text-[1.4rem] md:text-[3rem] my-[1rem] font-bold font-roboto tracking-normal'>
            Welcome To <span className='text-orange-500' >Dynastical</span>
        </div>
        <div className='py-4 text-orange-500 font-extrabold text-[1.2rem]'>
            Reign Your Digital Kingdom with Dynastical Communication
        </div>
        <div className='text-center font-body text-[1.1rem] md:text-lg'>
            Dynastical Communication is a full-service digital marketing and advertising agency in Pune that helps businesses increase their online visibility and reach their target audience. With a team of experienced professionals, Dynastical Communication provides a range of services including SEO, PPC advertising, social media marketing, email marketing, content marketing, and website design and development. At Dynastical Communication, we believe that your digital dreams can become a reality with the right strategy and execution. So, join us and reign your digital kingdom with confidence!
        </div>
    </div>
    <DirectorInfo />
    <AboutHero />
    <AboutEnd />
    </>
  )
}

export default About