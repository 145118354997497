import React from 'react'
import {BsBookmarks} from 'react-icons/bs'

import { Link } from 'react-router-dom'

function BlogTitle(props) {
  return (
    <>
    <div className='flex'>
        <div className='bg-orange-500 text-white py-6 text-center w-[5rem]'>
            <p className='px-3'>{props.date}</p>
            <p className='px-3'>{props.month}</p>
            <p className='px-3'>{props.year}</p>
        </div>
        <div className='bg-[#F6F6F6] py-2 md:w-[40rem]'>
            <Link to={props.slug}>
              <p className='px-3 py-4 font-semibold text-xl cursor-pointer'>{props.title}</p>
            </Link>
            <div className='flex gap-2 items-center px-3'>
                <BsBookmarks />
                <p className=''>{props.tag}</p>
            </div>
        </div>
    </div>
    </>
  )
}

export default BlogTitle