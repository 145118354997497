import React from 'react'
import {BsSquare} from 'react-icons/bs'

import { Link } from 'react-router-dom'

function PageHeading(props) {
  return (
    <>
    <div className='bg-[#152440] py-[1.5rem]'>
        <p className='text-center text-[3rem] font-roboto font-bold text-white tracking-wide'>{props.title}</p>
        <div className='flex gap-4 py-[1rem] items-center justify-center text-xl font-semoibold text-white font-roboto'>
            <Link className='hover:text-orange-500' to='/'>Home</Link >
            <BsSquare />
            <Link className='hover:text-orange-500' to=''>{props.pagename}</Link >
        </div>
    </div>
    </>
  )
}

export default PageHeading