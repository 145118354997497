import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import AboutStart from '../AboutStart'
import AboutHero from '../AboutHero'
import AboutEnd from '../AboutEnd'
import ClientTestimonials from '../ClientTestimonials'
import BlogsSection from '../BlogsSection'
import ContactForm from '../ContactForm'
import CarouselTemporary from '../Carousel/CarouselTemporary';

function Main() {

  const pathname = useLocation();

  useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

  return (
    <>
    <CarouselTemporary />
    <AboutStart />
    <AboutHero />
    <AboutEnd />
    <ClientTestimonials />
    <BlogsSection />
    <ContactForm />
    </>
  )
}

export default Main