import React from 'react'

import {BsArrowRight} from 'react-icons/bs'
import BlogTitle from './BlogTitle'

// import './styles/BlogSection.css'

import { Link } from 'react-router-dom'

function BlogsSection() {
  return (
    <div className='blogsecdiv'>
      <div>
          <p className='text-[2rem] p-[2.5rem] text-center md:text-start md:text-5xl font-semibold font-roboto md:py-[4rem] md:ml-[7rem]'>Latest Blog Posts</p>
      </div>
      <div className='md:flex justify-center w-full md:px-28 px-4'>
          <div className='grid md:grid-cols-2 gap-x-[14rem] gap-y-[2rem]'>
              <BlogTitle slug={'blog_one'} date={'22'} month={'FEB'} year={'2023'} title={'Unlock the Power of Trendy Digital Marketing & Advertising'} tag={'DIGITAL MARKETING'} />
              <BlogTitle slug={'blog_two'} date={'24'} month={'FEB'} year={'2023'} title={'Bringing Your Ideas To Life With Creative Graphic Designs'} tag={'GRAPHIC DESIGNING'} />
              <BlogTitle slug={'blog_three'} date={'06'} month={'MAR'} year={'2023'} title={"Let's color your brand with confidence"} tag={'HOLI FESTIVAL'} />
          </div>
      </div>
      <div className='text-blue-500 justify-center md:justify-end flex items-center gap-2 md:mr-[7rem] my-[1rem] text-xl'>
          <Link to="blog">See all </Link>
          <BsArrowRight />
      </div>
    </div>
  )
}

export default BlogsSection