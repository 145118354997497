import React from 'react'
import group from './images/logo/group.png'
import aboutend from './images/aboutend.jpg'
import response from './images/logo/response.png'
import solution from './images/logo/solution.png'
import idea from './images/logo/idea.png'
import value from './images/logo/value.png'
import customer_service from './images/logo/customer-support.png'

function AboutEnd() {
  return (
    <>
    <p className='text-[2rem] sm:text-[3rem] font-semibold font-roboto tracking-wide text-slate-800 text-center py-[2.5rem] md:pt-[3rem] md:pb-[1.3rem]'>Why Choose Us !!!</p>
    <hr className='mx-auto w-40 h-[0.1rem] bg-orange-600 mt-1 mb-10' />
    <div className='md:flex justify-center gap-20 items-top px-10 mb-20'>
        <div className=''>
            <div className='py-4'>
                <div className='flex justify-start items-center gap-6'>
                    <img className='w-[3.5rem] h-[3.5rem] bg-orange-500 rounded-full p-2' src={group} alt="" />
                    <p className='font-semibold text-2xl'>Experienced Team</p>
                </div>
                <p className='text-slate-600 font-base md:w-[25vw] my-3 mx-2'>Our team of experienced professionals has a deep understanding of digital marketing and advertising, ensuring that you get the best results from your campaigns.</p>
            </div>
            <div className='py-4'>
                <div className='flex justify-start items-center gap-6'>
                    <img className='w-[3.5rem] h-[3.5rem] bg-orange-500 rounded-full p-2' src={response} alt="" />
                    <p className='font-semibold text-2xl'>Results-Driven Approach</p>
                </div>
                <p className='text-slate-600 font-base md:w-[25vw] my-3 mx-2'>We are focused on delivering results-driven solutions that help businesses achieve their marketing goals and grow their online presence.</p>
            </div>
            <div className='py-4'>
                <div className='flex justify-start items-center gap-6'>
                    <img className='w-[3.5rem] h-[3.5rem] bg-orange-500 rounded-full p-2' src={solution} alt="" />
                    <p className='font-semibold text-2xl'>Customized Solutions</p>
                </div>
                <p className='text-slate-600 font-base md:w-[25vw] my-3 mx-2'>We offer customized digital marketing solutions based on the specific needs of each business, ensuring maximum ROI and growth opportunities.</p>
            </div>
        </div>
        <div className='my-10 md:my-0' >
            <div className='flex justify-center'>
                <img className='w-[70vw] md:w-[25vw] h-[25rem]' src={aboutend} alt="" />
            </div>
            <div className='flex justify-center'> 
                <div className='px-2 text-white bg-orange-500 w-[70vw] md:w-[25vw]'>
                    <p className='text-center p-3'>At Dynastical Communication, we offer a range of digital marketing and advertising services that are tailored to meet the unique needs of each client. Choose us as your partner for digital marketing success.</p>
                </div>
            </div>
        </div>
        <div className=''>
            <div className='py-4'>
                <div className='flex justify-start items-center gap-6'>
                    <img className='w-[3.5rem] h-[3.5rem] bg-orange-500 rounded-full p-2' src={idea} alt="" />
                    <p className='font-semibold text-2xl'>Innovation</p>
                </div>
                <p className='text-slate-600 font-base md:w-[25vw] my-3 mx-2'>We stay up-to-date with the latest trends and technologies in digital marketing and advertising, ensuring that your campaigns are always fresh and effective.</p>
            </div>
            <div className='py-4'>
                <div className='flex justify-start items-center gap-6'>
                    <img className='w-[3.5rem] h-[3.5rem] bg-orange-500 rounded-full p-2' src={value} alt="" />
                    <p className='font-semibold text-2xl'>Value for Money</p>
                </div>
                <p className='text-slate-600 font-base md:w-[25vw] my-3 mx-2'>We offer competitive pricing and ensure that you get the best value for your money.</p>
            </div>
            <div className='py-4'>
                <div className='flex justify-start items-center gap-6'>
                    <img className='w-[3.5rem] h-[3.5rem] bg-orange-500 rounded-full p-2' src={customer_service} alt="" />
                    <p className='font-semibold text-2xl'>Customer Service</p>
                </div>
                <p className='text-slate-600 font-base md:w-[25vw] my-3 mx-2'>We provide excellent customer service and are always available to answer your questions and address your concerns.</p>
            </div>
        </div>
    </div>
    </>
  )
}

export default AboutEnd