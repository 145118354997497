import React from 'react'
import './styles/AboutHero.css'

import digimark from './images/logo/digimark.png'
import contmark from './images/logo/content.png'
import webdev from './images/logo/responsive.png'

import { Link } from 'react-router-dom'

export default function AboutHero() {
  return (
    <>
    <div className='md:flex justify-center gap-2'>
        <div className='md:w-[50vw] p-[2rem] md:p-[5rem] '>
            <div className='text-blue-900 text-[1.7rem] md:text-3xl font-roboto font-semibold'>
                Welcome To <span className='text-orange-500' > DYNASTICAL</span>
            </div>
            <div className='py-4 text-orange-500 font-extrabold text-lg'>
                Reign Your Digital Kingdom with Dynastical Communication
            </div>
            <div className='md:text-justify font-body text-[1.1rem] md:text-lg'>
                Dynastical Communication is a full-service digital marketing and advertising agency in Pune that helps businesses increase their online visibility and reach their target audience. With a team of experienced professionals, Dynastical Communication provides a range of services including SEO, PPC advertising, social media marketing, email marketing, content marketing, and website design and development. At Dynastical Communication, we believe that your digital dreams can become a reality with the right strategy and execution. So, join us and reign your digital kingdom with confidence!
            </div>
            <div className='py-6'>
                <Link to='contact'>
                <button className='bg-orange-500 px-4 py-2 rounded-full text-white hover:bg-white hover:border border-black hover:text-orange-600 hover:drop-shadow-2xl font-roboto font-semibold'>
                    GET IN TOUCH
                </button>
                </Link>
            </div>
        </div>
        <div className='md:w-[50vw] py-[2rem] md:py-[5rem] md:px-[4rem] bg-orange-500'>
                <div className='my-[2rem] px-[0.8rem] sm:px-[2.5rem] flex justify-start items-center gap-10'>
                    <img className='w-16 bg-white p-1 rounded-lg' src={digimark} alt="" />
                    <div>
                        <p className='text-blue-900 text-[1.3rem] font-roboto font-semibold'>DIGITAL MARKETING</p>
                        <p className='text-white font-sans tracking-wide'>Social Media Marketing | Search Engine Marketing | Search Engine Optimization | Social Media Optimization</p>
                    </div>
                </div>
                <div className='my-[2rem] px-[0.8rem] sm:px-[2.5rem] flex justify-start items-center gap-10'>
                    <img className='w-16 bg-white p-1 rounded-lg' src={contmark} alt="" />
                    <div>
                        <p className='text-blue-900 text-[1.3rem] font-roboto font-semibold'>CONTENT MARKETING</p>
                        <p className='text-white font-sans tracking-wide'>Dynastical Communication creates high-quality content that helps businesses attract and retain customers.</p>
                    </div>
                </div>
                <div className='my-[2rem] px-[0.8rem] sm:px-[2.5rem] flex justify-start items-center gap-10'>
                    <img className='w-16 bg-white p-1 rounded-lg' src={webdev} alt="" />
                    <div>
                        <p className='text-blue-900 text-[1.3rem] font-roboto font-semibold'>WEBSITE DESIGN & DEVELOPEMENT</p>
                        <p className='text-white font-sans tracking-wide'>Dynastical Communication designs and develops responsive and user-friendly websites that are optimized for search engines and deliver a great user experience.</p>
                    </div>
                </div>
        </div>
    </div>
    </>
    )
}
