import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import BlogTitle from '../BlogTitle'

import fb from '../images/logo/fb.png'
import insta from '../images/logo/insta.png'
import { Helmet } from 'react-helmet';

function Blog() {

    const pathname = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

  return (
    <>
    <Helmet>
        <title>{ 'Dynastical Communication | Blog' }</title>
    </Helmet>
    <div className='sm:flex justify-center gap-20 mx-auto px-6 sm:px-20 py-[2rem]'>
        <div className=''>
            <p className='text-[1.7rem] text-center sm:text-[3rem] font-semibold font-roboto my-[3rem]'>Latest Blog Post</p>
            <div className='my-10'>
                <BlogTitle slug={'blog_one'} date={'22'} month={'FEB'} year={'2023'} title={'Unlock the Power of Trendy Digital Marketing & Advertising'} tag={'DIGITAL MARKETING'} />
            </div>
            <div className='my-10'>
                <BlogTitle slug={'blog_two'} date={'24'} month={'FEB'} year={'2023'} title={'Bringing Your Ideas To Life With Creative Graphic Designs'} tag={'GRAPHIC DESIGNING'} />
            </div>
            <div className='my-10'>
                <BlogTitle slug={'blog_three'} date={'06'} month={'MAR'} year={'2023'} title={"Let's color your brand with confidence"} tag={'HOLI FESTIVAL'} />
            </div>
        </div>
        <div className='py-12 flex justify-center'>
            <iframe title='facebook_timeline' src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FDynasticalCommunication%2F&tabs=timeline&width=340&height=580&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="340" height="580" style={{border:"2px", borderColor:"orange", overflow:"hidden"}} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
        </div>
        <div className='my-auto flex justify-center gap-4 sm:block'>
            <img className='my-6 w-[4rem] h-[4rem] cursor-pointer' src={fb} alt="" />
            <img className='my-6 w-[4rem] h-[4rem] cursor-pointer' src={insta} alt="" />
        </div>
    </div>
    </>
  )
}

export default Blog