import React, { useState ,useRef } from 'react'
import emailjs from '@emailjs/browser';

import telephone from './images/pexels-pixabay-209663.jpg'

function GetInTouch() {

    const form = useRef();

    const [submit, setSubmit] = useState('SUBMIT')

    const [message, setMessage] = useState('')

    const sendEmail = (e) => {
        e.preventDefault();
        
        setSubmit('Submitting Response ...')
        emailjs.sendForm('service_mcl9eta', 'template_gux7tj4', form.current, 'PApHXFlP83JnCoRW9')
          .then((result) => {
              console.log(result.text);
              setSubmit('SUBMIT')
              setMessage('Thank you for your message. We will contact you soon !')
          }, (error) => {
              console.log(error.text);
          });
    };

  return (
    <>
    <div className='md:flex justify-center'>
        <div className='bg-[#F6F6F6] px-10 pt-10 pb-10 md:w-[50%]'>
            <p className='text-center md:text-start text-[2.5rem] font-semibold px-1 py-4 font-roboto'>GET IN TOUCH</p>
            <div className="block rounded-lg bg-white p-6 shadow-lg dark:bg-neutral-700">
                <form ref={form} onSubmit={sendEmail}>
                    <div className="form-group mb-6">
                        <input type="text" name="user_name" className="form-control block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput7"
                        placeholder="Name"/>
                    </div>
                    <div className="form-group mb-6">
                        <input type="email" name="user_email" className="form-control block 
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput8"
                        placeholder="Email address"/>
                    </div>
                    <div className="form-group mb-6">
                        <input type="tel" name="user_contact" className="form-control block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput8"
                        placeholder="Contact Number"/>
                    </div>
                    <div className="form-group mb-6">
                        <textarea name="message" className="
                        form-control
                        block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                        " id="exampleFormControlTextarea13" rows="3" placeholder="Message" />
                    </div>
                    <button type="submit" className="
                    w-full
                    px-6
                    py-2.5
                    bg-orange-500
                    text-white
                    font-medium
                    text-base
                    leading-tight
                    uppercase
                    rounded
                    shadow-md
                    hover:bg-orange-500 hover:shadow-lg
                    focus:bg-orange-500 focus:shadow-lg focus:outline-none focus:ring-0
                    active:bg-orange-500 active:shadow-lg
                    transition
                    duration-150
                    ease-in-out">
                    {submit}
                    </button>
                    <p className='text-center my-[0.3rem] text-blue-600 font-semibold'>{message}</p>
                </form>
            </div>
        </div>
        <div className='md:w-[50%] hidden md:block'>
            <img className='w-full h-full' src={telephone} alt="" />
        </div>
    </div>
    </>
  )
}

export default GetInTouch