import React from 'react'

import director from './images/shubham_joshi.jpeg'

function DirectorInfo() {
  return (
    <>
    <div className="container sm:px-6 mx-auto">
        <p className='text-center text-[1.7rem] md:text-[3rem] font-bold font-roboto py-[2rem]'>Meet Our Director</p>
        <div className="md:flex items-top justify-center items-center gap-10">
            <div className="sm:w-[40vw] border-2 border-orange-500">
                <img src={director} alt="directors_info"
                className="w-full" />
            </div>
            <div className="sm:w-[40vw]">
                <div className="">
                    <p className='text-[1.7rem] md:text-[3rem] text-center md:text-start font-semibold mt-[2rem] md:mt-0 mb-5'>Mr. Shubham Joshi</p>
                    <p className='text-slate-800 text-justify sm:text-justify leading-relaxed text-lg'>Mr. Shubham Joshi, the Director of Dynastical Communication Private Limited, the leading digital marketing and advertising company in Pune, is a seasoned professional with many years of expertise in developing and implementing effective digital marketing strategies. He has in-depth knowledge of social media, email marketing, paid advertising, and content marketing, along with other digital marketing platforms. He has a proven history of utilising these channels to increase customer conversion rates, generate leads, and drive traffic. He is able to continually analyse and optimise campaigns to achieve the highest ROI thanks to his sharp eye for data and analytics. He works in close collaboration with his team and clients to create amazing outcomes and surpass expectations since he is an excellent communicator and collaborator.</p>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default DirectorInfo