import React,{ useState } from 'react'

import {HiOutlineMail} from 'react-icons/hi'
import {IoCallSharp} from 'react-icons/io5'
import logo from './images/logo.png'
import './styles/navbar.css'
import { motion } from 'framer-motion';

import { Link, NavLink } from 'react-router-dom'

export default function Navbar() {

    const [name, setname] = useState(true)

    const toggleMenu = () => {
        setname(!name)
    }

    const [rotate, setRotate] = useState(false);
    const [xvalue, setXvalue] = useState(false)
    const [yvalue, setYvalue] = useState(false)
    const [hide, sethide] = useState(false)
    const [color,setColor] = useState('bg-orange-600')

    const changeHamburger = ()=>{
        setRotate(!rotate);
        setXvalue(!xvalue);
        setYvalue(!yvalue);
        sethide(!hide);

        if (color === 'bg-orange-600') {
            setColor('bg-white')
        } else {
            setColor('bg-orange-600')
        }
    }

  return (
    <>
    <div className='bg-slate-200 lg:flex hidden justify-between overflow-hidden'>
        {/* left */}
        <div className='topbar-left flex py-2 pl-10 w-[50%]'>
            <div className='skew-x-[-25deg] flex'>
                <div className='border-black border-r px-2 text-slate-500'>
                   <Link to='contact' className='cursor-pointer'>Support</Link> 
                </div>
                <div className='border-black border-r px-3 text-slate-500'>
                   <Link to='privacy-policy' className='cursor-pointer'>Privacy-Policy</Link> 
                </div>
                <div className=' text-slate-500 px-3'>
                   <Link to='career' className='cursor-pointer'>Career</Link> 
                </div>
            </div>
        </div>
        {/* middle */}

        {/* right */}
        <div className=' topbar-right flex font-normal text-white bg-[#F36E21] py-2'>
            <div className='skew-x-[-25deg] flex'>
                <div className='flex items-center px-4'>
                    <div><HiOutlineMail /></div>
                    <div className='px-4 border-r border-white'>connectdynastical@gmail.com</div>
                    <div className='flex items-center gap-2 px-2'><IoCallSharp />+91 91757 72356</div>
                    <div className='flex items-center gap-2 px-2'><IoCallSharp />+91 87669 62067</div>
                </div>
            </div>
        </div>
    </div>
    <nav className='flex justify-between lg:px-40 md:px-20 px-10 py-4 shadow-md'>
        <div className='w-40 sm:w-[15rem]'>
            <Link to="/"><img src={logo} alt="digital marketing" /></Link>
        </div>
        <div className='menu-items flex items-center text-lg font-normal'>
            <ul className='hidden md:flex gap-6'>
                <NavLink aria-activedescendant='active' className='font-display hover:text-orange-600' to="/">HOME</NavLink>
                <NavLink aria-activedescendant='active' className='font-display hover:text-orange-600' to="about">ABOUT</NavLink>
                <NavLink aria-activedescendant='active' className='font-display hover:text-orange-600' to="service">SERVICES</NavLink>
                <NavLink aria-activedescendant='active' className='font-display hover:text-orange-600' to="blog">BLOGS</NavLink>
                <NavLink aria-activedescendant='active' className='font-display hover:text-orange-600' to="contact">CONTACT</NavLink>
            </ul>
        </div>
        <div
        className='md:hidden flex items-center z-[3]'>
            <div onClick={()=>{changeHamburger(); toggleMenu()}}>
                <motion.div 
                    animate={{
                        rotate: [rotate ? 45: 0],
                        x:[xvalue ? 4:0],
                        y: [yvalue ? 4:0],
                    }} className={`${color} w-8 h-[3px] rounded-full my-1 duration-300 ease-linear`}>
                </motion.div>
                <div className={`bg-orange-600 w-8 h-[3px] rounded-full my-1 ${hide ? 'hidden' : ''}`}></div>
                <motion.div
                    animate={{
                        rotate: [rotate ? -50: 0],
                        x:[xvalue ? 4:0],
                        y: [yvalue ? -3:0],
                    }} className={`${color} w-8 h-[3px] rounded-full my-1 duration-300 ease-linear`}>
                </motion.div>
            </div>
        </div>
        <ul  className={`overflow-hidden absolute top-0 h-screen md:hidden flex-col bg-[#F36E21] bg-opacity-90 z-[2] duration-300 ease-linear right-0 ${name ? 'w-0'  : 'w-[70%]' }`} >
            <NavLink to='/'>
                <li className='text-center hover:text-gray-600 font-medium tracking-wide mt-28 py-4 text-white px-10 cursor-pointer'>HOME</li>
            </NavLink>
            <NavLink to='about'>
                <li className='text-center hover:text-gray-600 font-medium tracking-wide py-4 text-white px-10 cursor-pointer'>ABOUT</li>
            </NavLink>
            <NavLink to='service'>
                <li className='text-center hover:text-gray-600 font-medium tracking-wide py-4 text-white px-10 cursor-pointer'>SERVICES</li>
            </NavLink>
            <NavLink to='blog'>
                <li className='text-center hover:text-gray-600 font-medium tracking-wide py-4 text-white px-10 cursor-pointer'>BLOGS</li>
            </NavLink>
            <NavLink to='career'>
                <li className='text-center hover:text-gray-600 font-medium tracking-wide py-4 text-white px-10 cursor-pointer'>CAREER</li>
            </NavLink>
            <NavLink to='contact'>
                <li className='text-center hover:text-gray-600 font-medium tracking-wide py-4 text-white px-10 cursor-pointer'>CONTACT</li>
            </NavLink>
        </ul>
    </nav> 
    </>
  )
}
