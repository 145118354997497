import React, { useState } from 'react'
import './CarouselTemporary.scss'
import carouselone from '../images/carousel-1.jpg'
import carouseltwo from '../images/carousel-2.jpg'
import {BsArrowLeft} from 'react-icons/bs'
import {BsArrowRight} from 'react-icons/bs'

function CarouselTemporary() {

    const [index, setIndex] = useState(0)

    const showPrev =()=>{
        setIndex(index === 0 ? 1 : (prev) => prev - 1)
    }
    
    const showNext =()=>{
        setIndex(index === 1 ? 0 : (prev) => prev + 1)
    }

  return (
    <div className='carouseltemporarybox'>
        <div className="carousel" style={{transform: `translateX(-${index * 100}vw)`}}>
            <div className="slide">
                <img src={carouselone} alt="" />
                <h1 className="tagline">WHERE YOUR DIGITAL <br /> DREAMS BECOMES REALITY</h1>
            </div>
            <div className="slide">
                <img src={carouseltwo} alt="" />
                <h1 className="tagline">TAKE OUR HELP TO <br /> REACH THE TOP LEVEL
                </h1>
            </div>
        </div>
        <div className="buttons">
            <button className='btn-icon' onClick={showPrev}><BsArrowLeft /></button>
            <button className='btn-icon' onClick={showNext}><BsArrowRight /></button>
        </div>
    </div>
  )
}

export default CarouselTemporary