import React from 'react'
import testimonial from './images/testimonial.jpg'

// import {BsArrowLeft, BsArrowRight} from 'react-icons/bs'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, A11y, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

function ClientTestimonials() {


  return (
    <>
    <div className='md:flex justify-center'>
        <div className='md:w-[50%] hidden md:block'>
            <img className='w-full h-[75vh]' src={testimonial} alt="" />
        </div>
        <div className='bg-[#F6F6F6] px-10 pb-20 md:w-[50%] flex items-center'>
            <div className='w-[90%]'>
                <p className='text-start text-[2rem] font-semibold font-roboto px-1 my-10'>What Our Clients Say!!!</p>
                <Swiper
                    modules={[Autoplay, A11y, Pagination]}
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    pagination={{ clickable: true }}
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => console.log('slide change')}
                    >
                    <SwiperSlide>
                        <div className='md:w-[35vw]'>
                            <p className='text-xl py-2'>Partnering with this company has been a game changer for my business. Their digital marketing strategies have significantly increased my online presence and sales. Highly recommended !</p>
                            <p className='text-xl py-2 my-[2rem]'>- Mantra Patil</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='md:w-[35vw]'>
                            <p className='text-xl py-2'>Our business' online presence has grown significantly as a result of the Dynastical team's competence in digital marketing, which has led to increased traffic and revenues. Strongly advised !</p>
                            <p className='text-xl py-2 my-[2rem]'>- Aditya Randive</p>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    </div>
    
    </>
  )
}

export default ClientTestimonials