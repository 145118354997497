import React, { useState ,useRef } from 'react'
import emailjs from '@emailjs/browser';

import {HiOutlineMail} from 'react-icons/hi'
import {MdCall} from 'react-icons/md'


function ContactForm() {

    const form = useRef();

    const [submit, setSubmit] = useState('SUBMIT')

    const [message, setMessage] = useState('')

    const sendEmail = (e) => {
        e.preventDefault();
        
        setSubmit('Submitting Response ...')
        emailjs.sendForm('service_mcl9eta', 'template_gux7tj4', form.current, 'PApHXFlP83JnCoRW9')
          .then((result) => {
              console.log(result.text);
              setSubmit('SUBMIT')
              setMessage('Thank you for your message. We will contact you soon !')
          }, (error) => {
              console.log(error.text);
          });
    };


  return (
    <>
    <div className="mt-[2rem] pb-[3rem] mx-auto bg-[#F6F6F6]">
        <section className=" text-gray-800">
            <div className="relative overflow-hidden bg-no-repeat bg-cover w-full">
                <iframe title='gmap' className='flex justify-center w-full h-[50vh] bg-[#2CCCC4]' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.3334798689693!2d73.82658351486437!3d18.513826387412927!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bfb12a6fe48d%3A0x7c5faf18901673bf!2sDynastical%20Communication%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1681211902839!5m2!1sen!2sin" width="1500" height="600" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className="text-gray-800 px-[1rem] md:px-[20rem] ">
                <div className="block rounded-lg shadow-lg py-10 md:py-12 px-2 md:px-6 border-orange-500 border-2"
                style={{marginTop: "-100px", background: "hsla(0, 0%, 100%, 0.8)", backdropFilter: "blur(30px)"}}>
                <div className="flex flex-wrap">
                    <div className="grow-0  basis-auto w-full xl:w-5/12 px-3 lg:px-6 mb-12 xl:mb-0">
                        <p className='text-center mb-[2rem] text-[3rem] font-roboto font-bold'>Contact Us</p>
                        <form ref={form} onSubmit={sendEmail}>
                            <div className="form-group mb-6">
                                <input type="text" name="user_name" className="form-control block
                                w-full
                                px-3
                                py-1.5
                                text-base
                                font-normal
                                text-gray-700
                                bg-white bg-clip-padding
                                border border-solid border-gray-300
                                rounded
                                transition
                                ease-in-out
                                m-0
                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput7"
                                placeholder="Name"/>
                            </div>
                            <div className="form-group mb-6">
                                <input type="email" name="user_email" className="form-control block 
                                w-full
                                px-3
                                py-1.5
                                text-base
                                font-normal
                                text-gray-700
                                bg-white bg-clip-padding
                                border border-solid border-gray-300
                                rounded
                                transition
                                ease-in-out
                                m-0
                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput8"
                                placeholder="Email address"/>
                            </div>
                            <div className="form-group mb-6">
                                <input type="tel" name="user_contact" className="form-control block
                                w-full
                                px-3
                                py-1.5
                                text-base
                                font-normal
                                text-gray-700
                                bg-white bg-clip-padding
                                border border-solid border-gray-300
                                rounded
                                transition
                                ease-in-out
                                m-0
                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput8"
                                placeholder="Contact Number"/>
                            </div>
                            <div className="form-group mb-6">
                                <textarea name="message" className="
                                form-control
                                block
                                w-full
                                px-3
                                py-1.5
                                text-base
                                font-normal
                                text-gray-700
                                bg-white bg-clip-padding
                                border border-solid border-gray-300
                                rounded
                                transition
                                ease-in-out
                                m-0
                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                                " id="exampleFormControlTextarea13" rows="3" placeholder="Message" />
                            </div>
                            <button type="submit" className="
                            w-full
                            px-6
                            py-2.5
                            bg-orange-500
                            text-white
                            font-medium
                            text-base
                            leading-tight
                            uppercase
                            rounded
                            shadow-md
                            hover:bg-orange-500 hover:shadow-lg
                            focus:bg-orange-500 focus:shadow-lg focus:outline-none focus:ring-0
                            active:bg-orange-500 active:shadow-lg
                            transition
                            duration-150
                            ease-in-out">
                            {submit}
                            </button>
                            <p className='text-center my-[0.3rem] text-blue-600 font-semibold'>{message}</p>
                        </form>
                    </div>
                    <div className="">
                        <div className="p-2">
                            <div className="p-2 flex items-center gap-4">
                                <div className="">
                                    <div className="p-4 bg-orange-500 text-xl text-white rounded-md shadow-md w-14 h-14 flex items-center justify-center">
                                        <HiOutlineMail />
                                    </div>
                                </div>
                                <div className="">
                                    <p className="font-bold mb-1">Email Support</p>
                                    <p className="text-gray-500 ">connectdynastical@gmail.com</p>
                                </div>
                            </div>
                            <div className="p-2 flex items-center gap-4">
                                <div className="">
                                    <div className="p-4 bg-orange-500 text-xl text-white rounded-md shadow-md w-14 h-14 flex items-center justify-center">
                                        <MdCall />
                                    </div>
                                </div>
                                <div className="">
                                    <p className="font-bold mb-1">Call Us At</p>
                                <div className='sm:flex'>
                                    <div>
                                        <a href='tel:+919175772356' className="text-gray-500">+919175772356</a>
                                    </div>
                                    <div>
                                        <a href='tel:+918766962067' className="text-gray-500 ml-0 lg:ml-2">+918766962067</a>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
    </div>
    </>
  )
}

export default ContactForm